import axios from 'axios';

async function getPrevisoes(filters: ApiFiltersType, token?: string | null) {
  try {
    const response = await axios({
      headers: {
        Authorization: token
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/desmame/previsoes`,
      params: {
        ...filters,
      }
    });
    return response.data
  } catch (error) {
    window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/login`
    console.error(error);
  }
}

async function getDesmamesOcorridos(filters: ApiFiltersType, token?: string | null) {
  try {
    const response = await axios({
      headers: {
        Authorization: token
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/desmame/`,
      params: {
        ...filters,
      }
    });
    return response.data
  } catch (error) {
    window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/login`
    console.error(error);
  }
}

export { getPrevisoes, getDesmamesOcorridos };
