import { Grid } from "@mui/material";
import { ReactNode, Children, useContext, useEffect } from "react";
import dayjs from 'dayjs';
import FarmTellBeefSmartLogo from '../assets/imgs/logo.svg';
import { LanguageContext, SelectedFiltersContext } from '../App';
import { useSearchParams } from 'react-router-dom';

interface PrintProps {
  reference: any,
  children?: ReactNode,
  farmName?: string,
  usedFilters?: any,
  chartTitle?: string,
  onlyMonths?: boolean
}

export default function PrintComponent(props: PrintProps) {
  const { reference, children, farmName, usedFilters, chartTitle, onlyMonths } = props;
  const { translations, setLanguage } = useContext(LanguageContext);
  const { selectedFilters } = useContext(SelectedFiltersContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const languageParam = searchParams.get('language') || 'pt';
    setLanguage(languageParam);
  }, [searchParams, setLanguage]);

  let dataAtual = new Date();
  let dataFormatada = String(dataAtual.getDate()).padStart(2, '0') + '/' + String(dataAtual.getMonth() + 1).padStart(2, '0') + '/' + dataAtual.getFullYear();
  let horaFormatada = String(dataAtual.getHours()).padStart(2, '0') + ':' + String(dataAtual.getMinutes()).padStart(2, '0');

  const handleDateFormat = (date: Date, endDate: boolean) => {
    if (date) {
      let result = dayjs(date);
      if (endDate && onlyMonths && result.date() !== result.endOf("month").date()) {
        result = result.endOf("month");
      }
      return String(result.format("DD/MM/YYYY"));
    }
  }

  const handleFilterTags = () => {
    if (selectedFilters && usedFilters) {
      return Object.keys(usedFilters).map((filter: string, index: number) => (
        <p key={index}>{usedFilters[filter]}: {selectedFilters[filter]}</p>
      ));
    }
  }

  return (
    <Grid className="print-page" ref={reference} >
      { translations && 
        <>
          <Grid container className="print-page__header" wrap="nowrap" >
            <Grid item>
              <img src={FarmTellBeefSmartLogo} alt="FarmTell Beef Smart Logo" className="print-page__logo" />
            </Grid>
            <Grid item container flexDirection="column">
              <Grid item container flexDirection="row" justifyContent="space-between" wrap="nowrap" marginLeft={2}>
                <p className="print-page__title">{chartTitle}</p>
                <Grid item container flexDirection="row" justifyContent="flex-end" gap={2} marginRight={2} height={3}>
                  <p className="print-page__property-name">{translations["lbl.react.propriedade"] + ": " + (farmName ? farmName.substring(0, 32) : '')}</p>
                  <div className="separator-dot" />
                  <p className="print-page__time-period">{translations["lbl.react.periodo"]}: {handleDateFormat(selectedFilters ? selectedFilters.startDate : "", false) + " " + translations["lbl.react.a"] + " " + handleDateFormat(selectedFilters ? selectedFilters.endDate : "", true)}</p>
                </Grid>
              </Grid>
              <Grid item className="print-page__filters">
                { handleFilterTags() }
              </Grid>
            </Grid>
          </Grid>
          <div className="separator-dotted-edges" /><br />
          <Grid width="100%">
            {Children.only(children)}
          </Grid>
          <Grid container className="print-page__footer">
            <p>{translations["lbl.react.impresso.em"] + " " + dataFormatada + " " + translations["lbl.react.as"] + " " + horaFormatada}</p>
          </Grid>
        </>
      }
    </Grid>
  );
}