import axios from 'axios';

async function getPropriedades(proCodigo: string | null, token?: string | null) {
  try {
    const response = await axios({
      headers: {
        Authorization: token,
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/generic/propriedades?proCodigo=${proCodigo}`,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export { getPropriedades };
