import React, { useCallback, useEffect, useState, useContext, useMemo, useRef } from 'react';
import BarChart from '../components/charts/BarChart';
import Tabs from '../components/Tabs';
import Filter from '../components/Filter';
import { getDesmamesOcorridos, getPrevisoes } from '../apis/apiDesmame';
import { Box, Grid } from '@mui/material';
import fieldsTables from '../utils/fieldsTables';
import { showFilters } from '../utils/configFilters';
import Table from '../components/Table';
import Badge from '../components/Badge';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import EmptyState from '../components/EmptyState';
import Loading from '../components/Loading';
import { LanguageContext } from '../App';
import AccordionCustom from '../components/ArcodionCustom';
import PageForPrint from '../components/PageForPrint';
import Printer from '../components/Printer';
import { getPropriedades } from '../apis/apiPropriedades';
import showColumns from '../utils/showColumns';
import PieChartCustom from '../components/charts/PieChart';
import GenericSummary from '../components/summaries/GenericSummary';
import GenericSummaryTotalizer from '../components/summaries/GenericSummaryTotalizer';

interface Animal {
  idVisual: string ;
  idEletronico: string | null;
  dataNascimento: string;
  idadeMeses?: number;
  dataDesmame?: string;
  prevParto: string;
  pesoNascimento: number;
  aniSexo: string;
  aniNumeroCab: string;
  dataPrevisao: string;
  idadeDesmameMeses?: number;
  pesoDesmameKg?: number;
  pesoDesmameArroba?: number;
  categoria: {
      catCodigo: number;
      catNome: string;
  };
  raca: {
      espCodigo: number;
      espNome: string;
      racCodigo: number;
      racNome: string;
  };
}

type FiltersPrintRefType = {
  espCodigo: string;
  racCodigo: string;
  catCodigo: string;
  lotCodigo: string;
  sexo: string;
  statusCria: string;
  tipoBusca: string;
};

type FarmsType = {
  proCodigo: number;
  proDescricao: string;
}

interface ChartData {
  "value": number,
  "name": String,
  "rawData": any
};

type TooltipType = [number, string, number?][];

interface HeaderData {
  tooltips: TooltipType[];
  data: Array<string | number>;
}

interface SummaryData {
  headerData: HeaderData;
  contentData: Array<string | number | Array<string | number>>;
  dataTooltips?: TooltipType[];
}

interface PieChartData {
  headerData: HeaderData;
  contentData: Array<string | number | Array<string | number>>;
  rawData: ChartData[];
}

function IndicadoresNascimentos() {
  const [loading, setLoading] = useState<boolean>(true);
  const [barChartDataByPrev, setBarChartDataByPrev] = useState<BarChartData[]>([]);
  const [barChartDataByWeaning, setBarChartDataByWeaning] = useState<BarChartData[]>([]);
  const [barChartDataByBirth, setBarChartDataByBirth] = useState<BarChartData[]>([]);
  const [barChartDataWeightByBirth, setBarChartDataWeightByBirth] = useState<BarChartData[]>([]);
  const [barChartDataByWeight, setBarChartDataByWeight] = useState<BarChartData[]>([]);
  const [pieChartDataBreed, setPieChartDataBreed] = useState<ChartData[] | PieChartData | any>([]);
  const [sumaryChartByWeightData, setSumaryChartByWeightData] = useState<SummaryData | any>([]);
  const [sumaryChartWeightBirth, setSumaryChartWeightBirth] = useState<SummaryData | any>([]);
  const [summaryTotalizerDataByBreed, setSummaryTotalizerDataByBreed] = useState<SummaryData | any>([]);
  const [pieChartDataSex, setPieChartDataSex] = useState<ChartData[] | PieChartData | any>([]);
  const [summaryTotalizerDataBySex, setSummaryTotalizerDataBySex] = useState<SummaryData | any>([]);
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const [allMonths, setAllMonths] = useState<string[]>([]);
  const [selectedSex, setSelectedSex] = useState<string[]>([]);
  const [allSex, setAllSex] = useState<string[]>([]);
  const [selectedBreed, setSelectedBreed] = useState<string[]>([]);
  const [allBreed, setAllBreed] = useState<string[]>([]);
  const [selectedAnimalsByPrev, setSelectedAnimalsByPrev] = useState<Animal[]>([]);
  const [selectedAnimalsByWeaning, setSelectedAnimalsByWeaning] = useState<Animal[]>([]);
  const [previsoesDesmame, setPrevisoesDesmame] = useState<Animal[]>([]);
  const [desmamesOcorridos, setDesmamesOcorridos] = useState<Animal[]>([]);
  const [searchParams] = useSearchParams();
  const { translations, setLanguage } = useContext(LanguageContext);
  const [datePlaceholder, setDatePlaceholder] = useState<string>("");
  const printRef1 = useRef(null);
  const printRef2 = useRef(null);
  const printRef3 = useRef(null);
  const printRef4 = useRef(null);
  const printRef5 = useRef(null);
  const printRef6 = useRef(null);
  const printRef7 = useRef(null);
  const [farmName, setFarmName] = useState<string>("");
  const [filtersPrintRef, setFiltersPrintRef] = useState<FiltersPrintRefType | undefined>(undefined);
  const [, setIsPrinting] = useState<boolean>(false);

  const mesEnum = useMemo(() => translations ? [
    translations["lbl.react.sem.mes"],
    translations["lbl.react.jan"],
    translations["lbl.react.fev"],
    translations["lbl.react.mar"],
    translations["lbl.react.abr"],
    translations["lbl.react.mai"],
    translations["lbl.react.jun"],
    translations["lbl.react.jul"],
    translations["lbl.react.ago"],
    translations["lbl.react.set"],
    translations["lbl.react.out"],
    translations["lbl.react.nov"],
    translations["lbl.react.dez"],
  ] : [], [translations]);

  const handleAnimalsByPrev = (animalList: Animal[] | any) => {
    const groupedAnimals = animalList.reduce((group: any, animal: Animal) => {
      group[animal.dataPrevisao.split('-')[0]+animal.dataPrevisao.split('-')[1]] = group[animal.dataPrevisao.split('-')[0]+animal.dataPrevisao.split('-')[1]] || [];
      group[animal.dataPrevisao.split('-')[0]+animal.dataPrevisao.split('-')[1]].push(animal);
      return group;
    }, {});
    const groupedAnimalsSorted = Object.entries(groupedAnimals).sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
    return groupedAnimalsSorted;
  }

  const handleAnimalsByWeaning = (animalList: Animal[] | any) => {
    const groupedAnimals = animalList.reduce((group: any, animal: Animal) => {
      if (animal.dataDesmame) {
        group[animal.dataDesmame.split('-')[0]+animal.dataDesmame.split('-')[1]] = group[animal.dataDesmame.split('-')[0]+animal.dataDesmame.split('-')[1]] || [];
        group[animal.dataDesmame.split('-')[0]+animal.dataDesmame.split('-')[1]].push(animal);
      }
      return group;
    }, {});
    const groupedAnimalsSorted = Object.entries(groupedAnimals).sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
    return groupedAnimalsSorted;
  }

  const handleAnimalsByBirth = (animalList: Animal[] | any) => {
    const groupedAnimals = animalList.reduce((group: any, animal: Animal) => {
      if (animal.dataNascimento) {
        group[animal.dataNascimento.split('-')[0]+animal.dataNascimento.split('-')[1]] = group[animal.dataNascimento.split('-')[0]+animal.dataNascimento.split('-')[1]] || [];
        group[animal.dataNascimento.split('-')[0]+animal.dataNascimento.split('-')[1]].push(animal);
      }
      return group;
    }, {});
    const groupedAnimalsSorted = Object.entries(groupedAnimals).sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
    return groupedAnimalsSorted;
  }

  const handleSelectBars = () => {
  }

  const handleSelectPie = () => {
  }

  const handleTableData = useCallback(() => {
    if (selectedTab === "previsao_desmama") {
      const animalsList = previsoesDesmame.filter((animal) => {
        return (selectedMonths.includes(mesEnum[parseInt(animal.dataPrevisao.substring(5,7))]+' - '+animal.dataPrevisao.substring(2,4)));
      });

      setSelectedAnimalsByPrev(animalsList);
    } else {
      const animalsList = desmamesOcorridos.filter((animal) => {
        const sex = animal.aniSexo === "M" ? translations["lbl.react.macho"] : animal.aniSexo === "F" ? translations["lbl.react.femea"] : translations["lbl.react.indefinido"];

        if (animal.dataDesmame) {
          return (selectedMonths.includes(mesEnum[parseInt(animal.dataDesmame.substring(5,7))] + ' - ' + animal.dataDesmame.substring(2,4))) && selectedSex.includes(sex) && selectedBreed.includes(animal.raca.racNome);
        } else {
          return false;
        }
      });

      setSelectedAnimalsByWeaning(animalsList);
    }
  }, [previsoesDesmame, desmamesOcorridos, mesEnum, selectedMonths, selectedSex, selectedBreed, selectedTab, translations]);

  const handleAnimalsBySex = useCallback((animalList: Animal[] | any) => {
    const chartData: PieChartData = {
      headerData: {
        tooltips: [
          [2, translations["lbl.react.calculo.peso.medio.animais.pesagem.desmame"]], 
          [3, translations["lbl.react.calculo.peso.medio.animais.pesagem.desmame"]]
        ],
        data: [translations["lbl.react.tabela.sexo"], translations["lbl.react.quantidade"], translations["lbl.react.peso.medio.kg"], translations["lbl.react.peso.medio.arroba"], translations["lbl.react.idade.media.meses"]],
      },
      contentData: [],
      rawData: []
    };
    const males = animalList.filter((animal: Animal) => animal.aniSexo === "M");
    const females = animalList.filter((animal: Animal) => animal.aniSexo === "F");

    if (males.length) {
      const heads = males.reduce((acc: number) => acc = acc + 1, 0);
      const headsWhithoutZeroWeight = males.filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number) => acc = acc + 1, 0);
      const averageWeight = males.filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameKg ? acc = acc + curr.pesoDesmameKg : acc, 0) / headsWhithoutZeroWeight;
      const averageWeightArroba = males.filter((animal: Animal) => animal.pesoDesmameArroba !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameArroba ? acc = acc + curr.pesoDesmameArroba : acc, 0) / headsWhithoutZeroWeight;
      const averageAge = males.reduce((acc: number, curr: Animal) => curr.idadeDesmameMeses ? acc = acc + parseInt('' + curr.idadeDesmameMeses) : acc, 0) / heads;

      const rawData = {
        name: translations["lbl.react.machos"],
        value: heads,
        rawData: males
      }

      chartData.rawData.push(rawData);
      chartData.contentData.push([
          translations["lbl.react.machos"],
          heads,
          averageWeight.toFixed(2).replace('.', ','),
          averageWeightArroba.toFixed(2).replace('.', ','),
          averageAge.toFixed(2).replace('.', ',')
        ]);
    }

    if (females.length) {
      const heads = females.reduce((acc: number) => acc = acc + 1, 0);
      const headsWhithoutZeroWeight = females.filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number) => acc = acc + 1, 0);
      const averageWeight = females.filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameKg ? acc = acc + curr.pesoDesmameKg : acc, 0) / headsWhithoutZeroWeight;
      const averageWeightArroba = females.filter((animal: Animal) => animal.pesoDesmameArroba !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameArroba ? acc = acc + curr.pesoDesmameArroba : acc, 0) / headsWhithoutZeroWeight;
      const averageAge = females.reduce((acc: number, curr: Animal) => curr.idadeDesmameMeses ? acc = acc + parseInt('' + curr.idadeDesmameMeses) : acc, 0) / heads;

      const rawData = {
        name: translations["lbl.react.femeas"],
        value: heads,
        rawData: females
      }

      chartData.rawData.push(rawData);
      chartData.contentData.push([
          translations["lbl.react.femeas"],
          heads,
          averageWeight.toFixed(2).replace('.', ','),
          averageWeightArroba.toFixed(2).replace('.', ','),
          averageAge.toFixed(2).replace('.', ',')
        ]);
    }

    return chartData;
  }, [translations]);

  const handleAnimalsByBreed = useCallback((animalList: Animal[] | any) => {
    const chartData: PieChartData = {
      headerData: {
        tooltips: [
          [2, translations["lbl.react.calculo.peso.medio.animais.pesagem.desmame"]], 
          [3, translations["lbl.react.calculo.peso.medio.animais.pesagem.desmame"]]
        ],
        data: [translations["lbl.react.tabela.raca"], translations["lbl.react.quantidade"], translations["lbl.react.peso.medio.kg"], translations["lbl.react.peso.medio.arroba"], translations["lbl.react.idade.media.meses"]],
      },
      contentData: [],
      rawData: []
    };
    const groupedAnimals = animalList.reduce((group: any, animal: Animal) => {
      group[(animal.raca.racNome).trim()] = group[(animal.raca.racNome).trim()] || [];
      group[(animal.raca.racNome).trim()].push(animal);
      return group;
    }, {});

    const groupedAnimalsSorted = Object.entries(groupedAnimals)
      .sort((a: any, b: any) => (
        b[1]
        .reduce((acc: number) => acc = acc + 1, 0) - a[1]
        .reduce((acc: number) => acc = acc + 1, 0)
      ));

    const mappedData = [];

    const othersLabels: string[] = [];

    if (groupedAnimalsSorted.length < 6) {
      mappedData.push(...groupedAnimalsSorted)
    } else {
      mappedData.push(...groupedAnimalsSorted.reduce((acc: any, curr: any, index: number) => {
        if (index < 4) {
          acc[index] = curr
        } else {
          if (!othersLabels.includes(curr[1][0].raca.racNome)) othersLabels.push(curr[1][0].raca.racNome)
          acc[4][1] = [...acc[4][1], ...curr[1]]
        }
        return acc
      }, [[],[],[],[],[translations["lbl.react.outros"], []]]))
    }

    for (let i = 0; i < mappedData.length; i ++) {
      const heads = mappedData[i][1].reduce((acc: number) => acc = acc + 1, 0);
      const headsWhithoutZeroWeight = mappedData[i][1].filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number) => acc = acc + 1, 0);
      const averageWeight = mappedData[i][1].filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameKg ? acc = acc + curr.pesoDesmameKg : acc, 0) / headsWhithoutZeroWeight;
      const averageWeightArroba = mappedData[i][1].filter((animal: Animal) => animal.pesoDesmameArroba !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameArroba ? acc = acc + curr.pesoDesmameArroba : acc, 0) / headsWhithoutZeroWeight;
      const averageAge = mappedData[i][1].reduce((acc: number, curr: Animal) => curr.idadeDesmameMeses ? acc = acc + parseInt('' + curr.idadeDesmameMeses) : acc, 0) / heads;

      const rawData = {
        name: mappedData[i][0],
        value: heads,
        rawData: mappedData[i][1]
      }

      chartData.rawData.push(rawData);
      chartData.contentData.push([
        mappedData[i][0], 
        heads, 
        averageWeight.toFixed(2).replace('.', ','), 
        averageWeightArroba.toFixed(2).replace('.', ','), 
        averageAge.toFixed(2).replace('.', ',')
      ]);
    }

    return chartData;
  }, [translations]);

  const handleSummaryTotalizerDataByBreedOrSex = useCallback((animalList: Animal[] | any) => {
    const chartData: PieChartData = {
      headerData: {
        tooltips: [
          [1, translations["lbl.react.calculo.peso.medio.animais.pesagem.desmame"]], 
          [2, translations["lbl.react.calculo.peso.medio.animais.pesagem.desmame"]]
        ],
        data: [translations["lbl.react.quantidade"], translations["lbl.react.peso.medio.kg"], translations["lbl.react.peso.medio.arroba"], translations["lbl.react.idade.media.meses"]],
      },
      contentData: [],
      rawData: []
    };

    const heads = animalList.reduce((acc: number) => acc = acc + 1, 0);
    const headsWhithoutZeroWeight = animalList.filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number) => acc = acc + 1, 0);
    const averageWeight = animalList.filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameKg ? acc = acc + curr.pesoDesmameKg : acc, 0) / headsWhithoutZeroWeight;
    const averageWeightArroba = animalList.filter((animal: Animal) => animal.pesoDesmameArroba !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameArroba ? acc = acc + curr.pesoDesmameArroba : acc, 0) / headsWhithoutZeroWeight;
    const averageAge = animalList.reduce((acc: number, curr: Animal) => curr.idadeDesmameMeses ? acc = acc + parseInt('' + curr.idadeDesmameMeses) : acc, 0) / heads;

    chartData.contentData = [
      heads, 
      averageWeight.toFixed(2).replace('.', ','), 
      averageWeightArroba.toFixed(2).replace('.', ','), 
      averageAge.toFixed(2).replace('.', ',')
    ];

    return chartData;
  }, [translations]);

  const handleSumaryChartByWeightData = useCallback((animalList: any, months: any) => {
    const chartData: SummaryData = {
      headerData: {
        tooltips: [],
        data: [...months]
      },
      dataTooltips: [
        [0, translations["lbl.react.calculo.peso.medio.animais.pesagem.desmame"], 1],
        [0, translations["lbl.react.calculo.peso.medio.animais.pesagem.desmame"], 2]
      ],
      contentData: []
    };

    let quantityRow: any = [translations["lbl.react.quantidade"]];
    let weightKgRow: any = [translations["lbl.react.peso.medio.kg"]];
    let weightArrobaRow: any = [translations["lbl.react.peso.medio.arroba"]];
    let ageRow: any = [translations["lbl.react.idade.media.meses"]];
    for (let i1 = 0; i1 < months.length; i1 ++) {
      const heads = animalList[i1][1].reduce((acc: number) => acc = acc + 1, 0);
      const headsWhithoutZeroWeight = animalList[i1][1].filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number) => acc = acc + 1, 0);
      const averageWeight = animalList[i1][1].filter((animal: Animal) => animal.pesoDesmameKg !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameKg ? acc = acc + curr.pesoDesmameKg : acc, 0) / headsWhithoutZeroWeight;
      const averageWeightArroba = animalList[i1][1].filter((animal: Animal) => animal.pesoDesmameArroba !== 0).reduce((acc: number, curr: Animal) => curr.pesoDesmameArroba ? acc = acc + curr.pesoDesmameArroba : acc, 0) / headsWhithoutZeroWeight;
      const averageAge = animalList[i1][1].reduce((acc: number, curr: Animal) => curr.idadeDesmameMeses ? acc = acc + parseInt('' + curr.idadeDesmameMeses) : acc, 0) / heads;

      quantityRow.push(animalList[i1][1].length);
      weightKgRow.push(Number.isNaN(averageWeight) ? 0 : averageWeight.toFixed(2).replace('.', ','));
      weightArrobaRow.push(Number.isNaN(averageWeightArroba) ? 0 : averageWeightArroba.toFixed(2).replace('.', ','));
      ageRow.push(averageAge.toFixed(2).replace('.', ','));
    }

    chartData.contentData = [
      quantityRow, 
      weightKgRow, 
      weightArrobaRow, 
      ageRow
    ];

    return chartData;
  }, [translations]);

  const handleDelete = (label: string) => {
    if (selectedMonths.includes(label)) {
      if (selectedMonths.length > 1) {
        const monthsList = selectedMonths.filter(months => months !== label);
        setSelectedMonths(monthsList);
      }
    } else {
      setSelectedMonths((prevState) => [...prevState, label]);
    }
    if (selectedSex.includes(label)) {
      if (selectedSex.length > 1) {
        const sexList = selectedSex.filter(sex => sex !== label);
        setSelectedSex(sexList);
      }
    } else {
      setSelectedSex((prevState) => [...prevState, label]);
    }
    if (selectedBreed.includes(label)) {
      if (selectedBreed.length > 1) {
        const breedList = selectedBreed.filter(breed => breed !== label);
        setSelectedBreed(breedList);
      }
    } else {
      setSelectedBreed((prevState) => [...prevState, label]);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, value: string) => {
    setBarChartDataByPrev([]);
    setSelectedMonths([]);
    setPrevisoesDesmame([]);
    setDesmamesOcorridos([]);
    if (value === "previsao_desmama") setDatePlaceholder(translations["lbl.react.data.previsao"]);
    else setDatePlaceholder(translations["lbl.react.data.desmame"]);
    setSelectedTab(value);
  };

  const handleGetAnimals = useCallback(async (filters: FiltersType) => {
    setLoading(true);

    const params = {
      proCodigo: searchParams.get('pro_codigo'),
      dataIni: filters.startDate,
      dataFim: filters.endDate,
      espCodigo: filters.espCodigo,
      racCodigo: filters.racCodigo,
      sexo: filters.sexo,
      lotCodigo: filters.lotCodigo,
      catCodigo: filters.catCodigo,
      aniSituacao: filters.statusCria,
      tipoBusca: filters.tipoBusca,
      ageInMonth: filters.ageInMonth.length > 0 ? filters.ageInMonth.join(',') : ''
    }

    const token = searchParams.get('token');
    
    if (selectedTab === 'previsao_desmama') {
      const previsoesDesmameResult = await getPrevisoes(params, token);

      if (previsoesDesmameResult != null) {
        setPrevisoesDesmame(previsoesDesmameResult);
        let groupedAnimaisByDate = [];
  
        groupedAnimaisByDate = handleAnimalsByPrev(previsoesDesmameResult);
  
        const mappedData = groupedAnimaisByDate.map((group: Animal[] | any) => (
          {
            "xData": mesEnum[parseInt(group[0].substring(4,6))]+' - '+group[0].substring(2,4),
            "yData": group[1].length,
            "rawData": group[1],
            "name": translations["lbl.react.animais"],
            "color": "#607d8b"
          }
        ));

        const months = mappedData ? mappedData.map((data: { xData: string; }) => data.xData) : [];
        setSelectedMonths(months);
        setAllMonths(months);
        setBarChartDataByPrev(mappedData);
      }
    }

    if (selectedTab === 'desmames_ocorridos') {
      const desmamesOcorridosResult = await getDesmamesOcorridos(params, token);
      if (desmamesOcorridosResult != null) {
        setDesmamesOcorridos(desmamesOcorridosResult);
        let groupedAnimais = handleAnimalsByWeaning(desmamesOcorridosResult);
        let groupedAnimaisByBirth = handleAnimalsByBirth(desmamesOcorridosResult);
  
        const mappedDataByDate = groupedAnimais.map((group: Animal[] | any) => (
          {
            "xData": mesEnum[parseInt(group[0].substring(4,6))]+' - '+group[0].substring(2,4),
            "yData": group[1].length,
            "rawData": group[1],
            "name": translations["lbl.react.animais"],
            "color": "#607d8b"
          }
        ));
        const mappedDataByBirthDate = groupedAnimaisByBirth.map((group: Animal[] | any) => (
          {
            "xData": mesEnum[parseInt(group[0].substring(4,6))]+' - '+group[0].substring(2,4),
            "yData": group[1].length,
            "rawData": group[1],
            "name": translations["lbl.react.animais"],
            "color": "#607d8b"
          }
        ));
        const mappedDataWeightByBirth = groupedAnimaisByBirth.map((group: Animal[] | any) => {
          const headsWhithoutZeroWeight = group[1].filter((animal: any) => animal.pesoDesmameKg !== 0).reduce((acc: number) => acc = acc + 1, 0);
          const averageWeight = group[1].filter((animal: any) => animal.pesoDesmameKg !== 0).reduce((acc: number, curr: any) => curr.pesoDesmameKg ? acc = acc + curr.pesoDesmameKg : acc, 0) / headsWhithoutZeroWeight;

          return ({
            "xData": mesEnum[parseInt(group[0].substring(4,6))]+' - '+group[0].substring(2,4),
            "yData": Number.isNaN(averageWeight) ? 0 : parseFloat(averageWeight.toFixed(2)),
            "rawData": group[1],
            "name": translations["lbl.react.animais"],
            "color": "#607d8b"
          }
        )});
        const mappedDataByWeight = groupedAnimais.map((group: Animal[] | any) => {
          const headsWhithoutZeroWeight = group[1].filter((animal: any) => animal.pesoDesmameKg !== 0).reduce((acc: number) => acc = acc + 1, 0);
          const averageWeight = group[1].filter((animal: any) => animal.pesoDesmameKg !== 0).reduce((acc: number, curr: any) => curr.pesoDesmameKg ? acc = acc + curr.pesoDesmameKg : acc, 0) / headsWhithoutZeroWeight;

          return ({
            "xData": mesEnum[parseInt(group[0].substring(4,6))]+' - '+group[0].substring(2,4),
            "yData": Number.isNaN(averageWeight) ? 0 : parseFloat(averageWeight.toFixed(2)),
            "rawData": group[1],
            "name": translations["lbl.react.animais"],
            "color": "#607d8b"
          }
        )});
  
        const months = mappedDataByDate ? mappedDataByDate.map((data: { xData: string; }) => data.xData) : [];
        const monthsByBirth = mappedDataByBirthDate ? mappedDataByBirthDate.map((data: { xData: string; }) => data.xData) : [];
        const breeds = desmamesOcorridosResult.map((animal:Animal) => animal.raca.racNome) || [];
        const uniqueBreeds = breeds.reduce((acc: string[], breed: string) => acc.includes(breed) ? acc : [...acc, breed], []);
        const sexs = desmamesOcorridosResult.map((animal:Animal) => animal.aniSexo === "M" ? translations["lbl.react.macho"] : animal.aniSexo === "F" ? translations["lbl.react.femea"] : "Indefinido" ) || [];
        const uniqueSexs = sexs.reduce((acc: string[], sex: string) => acc.includes(sex) ? acc : [...acc, sex], []);
        setSelectedMonths(months);
        setAllMonths(months);
        setSelectedSex(uniqueSexs);
        setAllSex(uniqueSexs);
        setSelectedBreed(uniqueBreeds);
        setAllBreed(uniqueBreeds);
        setPieChartDataSex(handleAnimalsBySex(desmamesOcorridosResult));
        setSummaryTotalizerDataBySex(handleSummaryTotalizerDataByBreedOrSex(desmamesOcorridosResult));
        setPieChartDataBreed(handleAnimalsByBreed(desmamesOcorridosResult));
        setSummaryTotalizerDataByBreed(handleSummaryTotalizerDataByBreedOrSex(desmamesOcorridosResult));
        setBarChartDataByWeaning(mappedDataByDate);
        setBarChartDataByBirth(mappedDataByBirthDate);
        setBarChartDataWeightByBirth(mappedDataWeightByBirth);
        setBarChartDataByWeight(mappedDataByWeight);
        setSumaryChartByWeightData(handleSumaryChartByWeightData(groupedAnimais, months));
        setSumaryChartWeightBirth(handleSumaryChartByWeightData(groupedAnimaisByBirth, monthsByBirth));
      }
    }
    
    const farmsResult = await getPropriedades(params.proCodigo, token).then((data: any) => data.filter((currFarm: FarmsType) => currFarm.proCodigo === Number(params.proCodigo))[0].proDescricao);
    setFarmName(farmsResult);

    if (translations) {
      setFiltersPrintRef({
        'espCodigo': translations["lbl.react.filter.especie"],
        'racCodigo': translations["lbl.react.filter.raca"],
        'catCodigo': translations["lbl.react.filter.categoria"],
        'lotCodigo': translations["lbl.react.filter.lote"],
        'sexo': translations["lbl.react.filter.sexo"],
        'statusCria': translations["lbl.react.filter.status"],
        'tipoBusca': translations["lbl.react.filter.tipo.busca"]
      });
    }

    setLoading(false)
  }, [searchParams, selectedTab, translations, handleAnimalsByBreed, handleAnimalsBySex, handleSumaryChartByWeightData, handleSummaryTotalizerDataByBreedOrSex, mesEnum]);

  const handleSearchButton = (filters: FiltersType) => {
    const mappedFilters = {
      ...filters,
      startDate: filters.startDate ? dayjs(filters.startDate).format("YYYY-MM-DD") : "",
      endDate: filters.endDate ? dayjs(filters.endDate).add(1, 'months').subtract(1, 'days').format("YYYY-MM-DD") : ""
    }
    handleGetAnimals(mappedFilters);
  }

  const handleCloseTable = (name: string) => {
    setSelectedMonths([]);
    setSelectedBreed([]);
    setSelectedSex([]);
  }

  useEffect(() => {
    if (selectedTab !== "") {
      handleGetAnimals({
        startDate: '',
        endDate: '',
        espCodigo: '',
        racCodigo: '',
        sexo: '',
        lotCodigo: '',
        catCodigo: '',
        statusCria: '',
        tipoBusca: '1',
        ageInMonth: []
      });
    }
  }, [handleGetAnimals, selectedTab]);

  useEffect(() => {
    const initialTab = searchParams.get('indicador') || "previsao_desmama";
    const languageParam = searchParams.get('language') || 'pt';
    setLanguage(languageParam);
    setSelectedTab(initialTab);
  }, [searchParams, setLanguage]);

  useEffect(() => {
    handleTableData();
  }, [handleTableData, translations]);

  return (
    <Grid container justifyContent="center">
      { translations &&
        <Grid item xs={12}>
          <div className="page-body">
            <span className="page-title">
              { translations["lbl.react.indicadores.desmame"] }
            </span>
            <Grid container rowSpacing={2} justifyContent="center">
              <Grid item xs={12}>
                { selectedTab &&
                  <Tabs handleChange={handleChangeTab} value={selectedTab} labels={[{ label: translations["lbl.react.previsao.desmame.maiuscula"], name: "previsao_desmama" }, { label: translations["lbl.react.desmame"], name: "desmames_ocorridos" }]}/>
                }
              </Grid>
              <Grid item xs={12}>
                <Filter
                  handleSearch={handleSearchButton}
                  datePlaceholder={datePlaceholder}
                  selectedTab={selectedTab}
                  showFilters={selectedTab === "previsao_desmama" ? showFilters.previsao_desmama : showFilters.desmames_ocorridos}
                  onlyMonths={true}
                  dateFormat="MM/yyyy"
                />
              </Grid>
              { loading === false ?
                <Grid item xs={12}>
                  {selectedTab === "previsao_desmama" && (
                    previsoesDesmame.length ?
                      <div>
                        <PageForPrint reference={printRef1} farmName={farmName} chartTitle={translations["lbl.react.previsao.desmames"]} onlyMonths={true} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container">
                            <Grid item xs={12}>
                              <AccordionCustom
                                title={translations["lbl.react.previsao.desmames"]}
                                subtitle={''}
                                amountText={translations["lbl.react.desmames.previstos.intervalo"]}
                                intervalTotal={barChartDataByPrev ? barChartDataByPrev.reduce((acc: number, previous: BarChartData) => acc = acc + previous.yData, 0) : 0}
                                topButtons={<Printer componentRef={printRef1} setIsPrinting={setIsPrinting} chartTitle={translations["lbl.react.previsao.desmames"]} />}
                              >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <Grid container item xs={12} pb={2} mb={3}>
                                    <BarChart
                                      data={barChartDataByPrev}
                                      barLabel={translations["lbl.react.animais"]}
                                      sideLabel={translations["lbl.react.quantidade.animais"]}
                                      handleSelectBar={handleSelectBars}
                                    />
                                  </Grid>
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                            { selectedMonths.length > 0 &&
                              <Box className="hide-to-print" sx={{ width: "100%", backgroundColor: "#efefef", border: "1px solid #e7ebee", borderRadius: "3px", margin: "0 16px 16px 16px"} }>
                                <Grid item container p={2}>
                                  <Grid item xs={12} mb={2}>
                                    <span className="c-b-cinza-icons chart-warning">{translations["lbl.react.para.ocultar.ou.exibir.clique"] + "."}</span>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <span>{translations["lbl.react.meses.selecionados"]}</span>
                                  </Grid>
                                  <Grid item container xs={12} mt={2} columnSpacing={2}>
                                    {
                                      allMonths.map((month) => <Badge label={month} handleDelete={handleDelete} key={month} deletable={selectedMonths.length !== 1 || !selectedMonths.includes(month)} addOrDel={selectedMonths.includes(month)} />)
                                    }
                                  </Grid>
                                </Grid>
                              </Box>
                            }
                          </Grid>
                        </PageForPrint>
                        { selectedMonths.length > 0 &&
                          <Table
                            columns={fieldsTables(translations).previsaoDesmame}
                            rows={selectedAnimalsByPrev}
                            idColumn='aniCodigo'
                            sortColumn='prevParto'
                            tableTitle={translations["lbl.react.dados.para.meses.selecionados"]}
                            handleCloseTable={handleCloseTable}
                            name="previsao"
                          />
                        }
                      </div>
                    :
                      <div>
                        <EmptyState />
                      </div>
                    )
                  }
                  { selectedTab === "desmames_ocorridos" && (
                    desmamesOcorridos.length ?
                      <div>
                        <PageForPrint reference={printRef2} farmName={farmName} chartTitle={translations["lbl.react.quantidade.animais.desmamados.por.mes.desmame"]} onlyMonths={true} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container">
                            <Grid item xs={12}>
                              <AccordionCustom
                                title={translations["lbl.react.quantidade.animais.desmamados.por.mes.desmame"]}
                                subtitle={''}
                                amountText={translations["lbl.react.desmames.intervalo"]}
                                intervalTotal={barChartDataByWeaning ? barChartDataByWeaning.reduce((acc: number, previous: BarChartData) => acc = acc + previous.yData, 0) : 0}
                                topButtons={<Printer componentRef={printRef2} setIsPrinting={setIsPrinting} chartTitle={translations["lbl.react.quantidade.animais.desmamados.por.mes.desmame"]} />}
                              >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <BarChart
                                    data={barChartDataByWeaning}
                                    barLabel={translations["lbl.react.animais"]}
                                    sideLabel={translations["lbl.react.quantidade.animais"]}
                                    handleSelectBar={handleSelectBars}
                                  />
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                          </Grid>
                        </PageForPrint>
                        <PageForPrint reference={printRef5} farmName={farmName} chartTitle={translations["lbl.react.peso.desmame.por.mes.desmame"]} onlyMonths={true} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container" marginTop={2}>
                            <Grid item xs={12}>
                              <AccordionCustom
                                title={translations["lbl.react.peso.desmame.por.mes.desmame"]}
                                subtitle={"*" + translations["lbl.react.calculo.peso.medio.apenas.animais.peso.desmame.maior.zero"] + "."}
                                amountText={translations["lbl.react.desmames.intervalo"]}
                                intervalTotal={barChartDataByWeight ? barChartDataByWeight.reduce((acc: number, previous: BarChartData) => acc = acc + previous.rawData.length, 0) : 0}
                                topButtons={<Printer componentRef={printRef5} setIsPrinting={setIsPrinting} chartTitle={translations["lbl.react.peso.desmame.por.mes.desmame"]} />}
                              >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <BarChart
                                    data={barChartDataByWeight}
                                    barLabel={translations["lbl.react.peso.desmame.kg"]}
                                    sideLabel={translations["lbl.react.peso.desmame.kg"]}
                                    handleSelectBar={handleSelectBars}
                                  />
                                  <Grid container justifyContent="center" alignItems="center" flexDirection="column">
                                    <GenericSummary chartData={sumaryChartByWeightData} skipFirstHeader={true} tooltipIcon={false} />
                                    <GenericSummaryTotalizer chartData={summaryTotalizerDataByBreed} tooltipIcon={false} />
                                  </Grid>
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                          </Grid>
                        </PageForPrint>
                        <Grid item container mt={2}>
                          <Grid item xs mr={2} justifyContent="center">
                            <PageForPrint reference={printRef3} farmName={farmName} onlyMonths={true} chartTitle={translations["lbl.react.desmames.x.sexo"]} usedFilters={filtersPrintRef}>
                              <PieChartCustom
                                data={pieChartDataSex}
                                title={translations["lbl.react.desmames.x.sexo"]}
                                subtitle={''}
                                handleSelectBar={handleSelectPie}
                                name="sex"
                                setIsPrinting={setIsPrinting}
                                componentRefPrint={printRef3}
                                skipFirstHeader={false}
                                totalizerData={summaryTotalizerDataBySex}
                                tooltipIcon={false}
                              />
                            </PageForPrint>
                          </Grid>
                          <Grid item xs justifyContent="center">
                            <PageForPrint reference={printRef4} farmName={farmName} onlyMonths={true} chartTitle={translations["lbl.react.desmames.x.raca"]} usedFilters={filtersPrintRef}>
                              <PieChartCustom
                                data={pieChartDataBreed}
                                title={translations["lbl.react.desmames.x.raca"]}
                                subtitle={''}
                                handleSelectBar={handleSelectPie}
                                name="breed"
                                setIsPrinting={setIsPrinting}
                                componentRefPrint={printRef4}
                                skipFirstHeader={false}
                                totalizerData={summaryTotalizerDataByBreed}
                                tooltipIcon={false}
                              />                      
                            </PageForPrint>
                          </Grid>
                        </Grid>
                        <PageForPrint reference={printRef6} farmName={farmName} chartTitle={translations["lbl.react.quantidade.de.animais.desmamados.mes.nascimento"]} onlyMonths={true} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container" marginTop={2}>
                            <Grid item xs={12}>
                              <AccordionCustom
                                title={translations["lbl.react.quantidade.de.animais.desmamados.mes.nascimento"]}
                                subtitle={''}
                                amountText={translations["lbl.react.desmames.intervalo"]}
                                intervalTotal={barChartDataByBirth ? barChartDataByBirth.reduce((acc: number, previous: BarChartData) => acc = acc + previous.yData, 0) : 0}
                                topButtons={<Printer componentRef={printRef6} setIsPrinting={setIsPrinting} chartTitle={translations["lbl.react.quantidade.de.animais.desmamados.mes.nascimento"]} />}
                              >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <BarChart
                                    data={barChartDataByBirth}
                                    barLabel={translations["lbl.react.animais"]}
                                    sideLabel={translations["lbl.react.quantidade.animais"]}
                                    handleSelectBar={handleSelectBars}
                                  />
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                          </Grid>
                        </PageForPrint>
                        <PageForPrint reference={printRef7} farmName={farmName} chartTitle={translations["lbl.react.peso.ao.desmame.por.mes.nascimento"]} onlyMonths={true} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container" marginTop={2}>
                            <Grid item xs={12}>
                              <AccordionCustom
                                title={translations["lbl.react.peso.ao.desmame.por.mes.nascimento"]}
                                subtitle={"*" + translations["lbl.react.calculo.peso.medio.apenas.animais.peso.desmame.maior.zero"] + "."}
                                amountText={translations["lbl.react.desmames.intervalo"]}
                                intervalTotal={barChartDataWeightByBirth ? barChartDataWeightByBirth.reduce((acc: number, previous: BarChartData) => acc = acc + previous.rawData.length, 0) : 0}
                                topButtons={<Printer componentRef={printRef7} setIsPrinting={setIsPrinting} chartTitle={translations["lbl.react.peso.ao.desmame.por.mes.nascimento"]} />}
                              >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <BarChart
                                    data={barChartDataWeightByBirth}
                                    barLabel={translations["lbl.react.peso.desmame.kg"]}
                                    sideLabel={translations["lbl.react.peso.desmame.kg"]}
                                    handleSelectBar={handleSelectBars}
                                  />
                                  <Grid container justifyContent="center" alignItems="center" flexDirection="column">
                                    <GenericSummary chartData={sumaryChartWeightBirth} skipFirstHeader={true} tooltipIcon={false} />
                                    <GenericSummaryTotalizer chartData={summaryTotalizerDataByBreed} tooltipIcon={false} />
                                  </Grid>
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                          </Grid>
                        </PageForPrint>
                        { selectedMonths.length > 0 &&
                          <Grid item xs={12}>
                            <Box sx={{ width: "100%", backgroundColor: "#efefef", border: "1px solid #e7ebee", borderRadius: "3px", marginTop: "16px"}}>
                              <Grid item container p={2}>
                                <Grid item xs={12} mb={2}>
                                  <span className="c-b-cinza-icons chart-warning">{translations["lbl.react.para.ocultar.ou.exibir.clique"] + "."}</span>
                                </Grid>
                                <Grid item xs={12}>
                                  <span>{translations["lbl.react.meses.selecionados"]}</span>
                                </Grid>
                                <Grid item container xs={12} mt={2} columnSpacing={2}>
                                  {
                                    allMonths.map(month => <Badge label={month} handleDelete={handleDelete} key={month} deletable={selectedMonths.length !== 1 || !selectedMonths.includes(month)} addOrDel={selectedMonths.includes(month)} />)
                                  }
                                </Grid>
                                <Grid item container xs={12} mt={2}>
                                  <Grid item container xs={3}>
                                    <Grid item xs={12}>
                                      <span>{translations["lbl.react.sexos.selecionados"]}</span>
                                    </Grid>
                                    <Grid item container xs={12} mt={2} columnSpacing={2}>
                                      {
                                        allSex.map(sex => <Badge label={sex} handleDelete={handleDelete} key={sex} deletable={selectedSex.length !== 1 || !selectedSex.includes(sex)} addOrDel={selectedSex.includes(sex)} />)
                                      }
                                    </Grid>
                                  </Grid>
                                  <Grid item container xs={9}>
                                    <Grid item xs={12}>
                                      <span>{translations["lbl.react.racas.selecionadas"]}</span>
                                    </Grid>
                                    <Grid item container xs={12} mt={2} columnSpacing={2}>
                                      {
                                        allBreed.map(breed => <Badge label={breed} handleDelete={handleDelete} key={breed} deletable={selectedBreed.length !== 1 || !selectedBreed.includes(breed)} addOrDel={selectedBreed.includes(breed)} />)
                                      }
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                            <Table
                              columns={fieldsTables(translations).desmamesOcorridos}
                              rows={selectedAnimalsByWeaning}
                              idColumn='aniCodigo'
                              sortColumn='idVisual'
                              tableTitle={translations["lbl.react.dados.informacoes.selecionadas"]}
                              tableSubTitle={<p className="c-b-cinza-icons chart-warning">*{translations["lbl.react.table.desmames.ocorridos.subtitle"]}</p>}
                              handleCloseTable={handleCloseTable}
                              name="desmames"
                              showColumns={showColumns.desmamesOcorridos}
                            />
                          </Grid>
                        }
                      </div>
                    :
                      <div>
                        <EmptyState />
                      </div>
                    )
                  }
                </Grid>
                :
                <Loading />
              }
            </Grid>
          </div>
        </Grid>
      }
    </Grid>
  );
}

export default IndicadoresNascimentos;