import dayjs from "dayjs";
import React, { useContext } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR, es } from 'date-fns/locale';
import { LanguageContext } from '../App';
registerLocale('pt', ptBR);
registerLocale('es', es)

interface SelectProps {
  handleChange?: any,
  handleClear?: any,
  handleClickOutside?: any,
  placeholder: string,
  startDate: Date | null,
  endDate: Date | null,
  onlyMonths: boolean | undefined,
  dateFormat: string
  limitMaxDate: boolean
}

export default function DataPickerCustom(props: SelectProps) {
  const { handleChange, handleClear, handleClickOutside, startDate, endDate, placeholder, onlyMonths, dateFormat, limitMaxDate } = props;
  const { translations, language } = useContext(LanguageContext);

  const mesEnum = translations ? [
    translations["lbl.react.jan"],
    translations["lbl.react.fev"],
    translations["lbl.react.mar"],
    translations["lbl.react.abr"],
    translations["lbl.react.mai"],
    translations["lbl.react.jun"],
    translations["lbl.react.jul"],
    translations["lbl.react.ago"],
    translations["lbl.react.set"],
    translations["lbl.react.out"],
    translations["lbl.react.nov"],
    translations["lbl.react.dez"],
  ] : [];
  
  const handleValue = () => {
    const startDay = startDate ? startDate.getDate() : ""
    const startMonth = startDate ? mesEnum[startDate.getMonth()] : ""
    const startYear = startDate ? startDate.getFullYear() : ""
    const endDay = endDate ? endDate.getDate() : ""
    const endMonth = endDate ? mesEnum[endDate.getMonth()] : ""
    const endYear = endDate ? endDate.getFullYear() : ""
    if (startDate && onlyMonths) return `${startMonth} ${startYear} - ${endMonth} ${endYear}`
    else if (startDate) return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`
    else return ""
  }

  const handleMaxDate = () => {
    const localStartDate = !startDate ?  dayjs().toDate() : startDate
    const maxPickabledDate = dayjs().add(364, 'days').toDate()
    const maxDate = dayjs(localStartDate).add(364, 'days').toDate()

    const today = dayjs().toDate()
    if (maxDate > today && limitMaxDate) return today
    if (maxDate > maxPickabledDate) return maxPickabledDate
    else return maxDate
  }

  return (
    <DatePicker
      className="select-custom_date"
      placeholderText={placeholder}
      selected={null}
      onChange={handleChange}
      selectsRange
      startDate={startDate}
      onCalendarOpen={handleClear}
      onClickOutside={handleClickOutside}
      endDate={endDate}
      dateFormat={dateFormat}
      showMonthYearPicker={onlyMonths}
      locale={language}
      value={handleValue()}
      minDate={null}
      maxDate={handleMaxDate()}
    />
  );
};