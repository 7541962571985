import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid } from '@mui/material';
import { LanguageContext } from '../App';

export default function Loading() {
  const { translations } = React.useContext(LanguageContext);
  return (
    <Grid container item display="flex" my={20} py={20} flexDirection="column" justifyContent="center" alignItems="center">
      <Grid item mt={10} mb={2}>
        <CircularProgress />
      </Grid>
      <Grid>
        <span>{ translations["lbl.react.carregando"] }</span>
      </Grid>
    </Grid>
  );
}