import { RouterProvider } from 'react-router-dom';
import { UseTranslations } from './utils/useTranslations';
import router from './Router';
import './assets/css/main.scss';
import React, { useEffect, useState } from 'react';

export const LanguageContext = React.createContext<any>({});
export const SelectedFiltersContext = React.createContext<any>({});

function App() {
  const [language, setLanguage] = useState('pt');
  const [translations, setTranslations] = useState<any>()
  const [selectedFilters, setSelectedFilters] = useState<any>()

  useEffect(() => {
    function fetchTranslations() {
      const translations = UseTranslations(language);
      setTranslations(translations);
    }
    fetchTranslations();
  }, [language]);

  return (
    <LanguageContext.Provider value={{ setLanguage, translations, language }}>
      <SelectedFiltersContext.Provider value={{ selectedFilters, setSelectedFilters }}>
        <RouterProvider router={router} />
      </SelectedFiltersContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;