import { Button } from '@mui/material';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useReactToPrint } from 'react-to-print';
import { useContext, useEffect } from 'react';
import { LanguageContext } from '../App';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';

//Considerar o uso da biblioteca React-pdf para ter maior controle das impressões;

interface PrintComponentProps {
  componentRef: React.MutableRefObject<null>;
  setIsPrinting?: React.Dispatch<React.SetStateAction<boolean>>;
  chartTitle?: string;
}

export default function PrintComponent(props: PrintComponentProps) {
  const { translations, setLanguage } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const setIsPrinting = props.setIsPrinting;
  
  useEffect(() => {
    const languageParam = searchParams.get('language') || 'pt';
    setLanguage(languageParam);
  }, [searchParams, setLanguage]);

  const printPage = useReactToPrint({
    documentTitle: props.chartTitle,
    content: () => props.componentRef.current,
    onAfterPrint: () => {
      setIsPrinting && setIsPrinting(false);
    }
  });

  const handlePrint = () => {
    setIsPrinting && setIsPrinting(true);
    setTimeout(() => {
      printPage();
    }, 10);
  }

  return (
    <Tooltip title={translations["lbl.react.tooltip.recomendacao.orientacao.impressao"]}><Button onClick={handlePrint} className={ "btn-secundario btn-print-small hide-to-print" } variant="contained"><LocalPrintshopIcon /></Button></Tooltip>
  );
}
