import * as React from 'react';
import { Grid } from '@mui/material';
import emptyStateIcon from '../assets/imgs/emptyStateIcon.svg'
import { LanguageContext } from '../App';

interface EmptyStateProps { emptyTab?: boolean };

export default function EmptyState(props: EmptyStateProps) {
  const { translations } = React.useContext(LanguageContext);
  const emptyTabValue = props.emptyTab ? props.emptyTab : false;

  return (
    <Grid item container my={10} alignItems="center" flexDirection="column">
      <Grid item xs={12} my={6}>
        <img src={emptyStateIcon} alt="empty state icon" />
      </Grid>
      <Grid item container xs={12} my={2} textAlign="center" fontSize="16px" rowSpacing={1} display="flex" flexDirection="column">
        <Grid item>
          { emptyTabValue ? <span className="s-verde-escuro">{ translations["lbl.react.existe.nenhum.animal.selecionado.para.esta.aba"] }</span>
            : <span className="s-verde-escuro">{ translations["lbl.react.nao.encontramos.nenhum"] }</span> }
        </Grid>
        <Grid item>
          { emptyTabValue ? <span className="s-verde-escuro">{ translations["lbl.react.por.favor.altere.a.aba"] }</span>
            : <span className="s-verde-escuro">{ translations["lbl.react.sua.busca.tente.novamente"] }</span> }
        </Grid>
      </Grid>
    </Grid>
  );
}