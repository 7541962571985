import { DataGrid, GridColDef, GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import { GRID_DEFAULT_LOCALE_TEXT, ROWS_PER_PAGE } from '../utils/localeTextConstants';
import { Box, Button, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import arrowIcon from '../assets/imgs/arrowDownCircleIcon.svg'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { LanguageContext } from '../App';
import { useContext, ReactNode, useEffect } from 'react';

interface TableProps {
  rows: any,
  columns: GridColDef[],
  idColumn: string | string[],
  sortColumn: string,
  tableTitle: ReactNode,
  tableSubTitle?: ReactNode,
  handleCloseTable?: any,
  name: string
  showColumns?: any,
  printButton?: boolean,
  hasCheckbox?: boolean
};

function CustomToolbar() {
  const { translations } = useContext(LanguageContext);
  const apiRef = useGridApiContext();

  const handleDonwloadCSV = () => apiRef.current.exportDataAsCsv({utf8WithBom: true, delimiter: ';'});

  return (
    <GridToolbarContainer>
      <Button startIcon={<img src={arrowIcon} alt="download icon" />} className="btn-primario" variant="contained" onClick={handleDonwloadCSV}>{ translations["lbl.react.btn.download.csv"] }</Button>
    </GridToolbarContainer>
  );
}

function handlePageName() {
  const item = document.querySelector(".css-levciy-MuiTablePagination-displayedRows")
  if (item && item.textContent)  item.textContent = item?.textContent?.replace("of", "de")
}

export default function DataTable(props: TableProps) {
  const { rows, columns, idColumn, sortColumn, tableTitle, tableSubTitle, handleCloseTable, name, showColumns, printButton = true, hasCheckbox = true } = props
  const { translations } = useContext(LanguageContext);

  useEffect(() => {
    if (rows && columns) handlePageName();
  }, [rows, columns])

  function acessarObjeto(row: Reproduction, acessoArray: string[]) {
    return acessoArray.reduce((obj: Reproduction, key: string) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, row);
  }
  
  return (
    <Box sx={{ width: "100%", backgroundColor: "#ffffff", border: "1px solid #e7ebee", borderRadius: "3px", marginTop: "16px", minHeight: "100px", boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)"} }>
      <Grid item container p={2}>
        <Grid item container justifyContent='space-between' xs={12}>
          <Grid>
            {tableTitle}
            {tableSubTitle ? tableSubTitle : null}
          </Grid>
          <Grid item onClick={() => handleCloseTable(name)} className='pointer'>
            <CloseOutlinedIcon />
          </Grid>
        </Grid>
        <Grid item container xs={12} mt={2} border='none'>
          <div style={{ width: '100%' }}>
            {
              rows && columns &&
              <DataGrid
                processRowUpdate={handlePageName}
                rows={rows}
                columns={columns}
                autoHeight
                localeText={GRID_DEFAULT_LOCALE_TEXT(translations)}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 50 },              
                  },
                  sorting: {
                    sortModel: [{ field: sortColumn, sort: 'asc' }],
                  },
                  columns: {
                    columnVisibilityModel: showColumns ? showColumns : {}
                  },
                }}
                getRowId={Array.isArray(idColumn) ? (row) => acessarObjeto(row, idColumn) : (row) => row[idColumn]}
                pageSizeOptions={[50, 100]}
                checkboxSelection={hasCheckbox}
                slots={printButton ? {
                  toolbar: CustomToolbar,
                  columnMenuIcon: KeyboardArrowDownIcon
                } : undefined}
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: ROWS_PER_PAGE(translations).labelRowsPerPage
                  }
                }}
              />
            }
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}