import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface MultilineTextFieldsProps {
  width?: string,
  placeHolder?: string,
  maxRows?: number,
  handleChange: any,
  currentValue: string,
  maxLength?: number
};

export default function MultilineTextFields(props: MultilineTextFieldsProps) {
  const { width, placeHolder, maxRows, handleChange, currentValue, maxLength = 200 } = props;

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: width ? width : '100px' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-multiline-flexible"
          multiline
          inputProps={{ maxLength: maxLength }}
          value={currentValue}
          placeholder={placeHolder ? placeHolder : ""}
          onChange={handleChange}
          maxRows={maxRows ? maxRows : 4}
        />
      </div>
    </Box>
  );
}
