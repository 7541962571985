import * as React from 'react';
import { Grid, Tooltip } from '@mui/material';
import { separateByIatf1, separateByIatf2, separateByIatf3 } from '../../utils/separateData';
import { LanguageContext } from '../../App';
import { useContext } from 'react';

interface IatfSummaryProps {
  data: Reproduction[];
}

export default function IatfSummary(props: IatfSummaryProps) {
  const { data } = props
  const { translations } = useContext(LanguageContext);

  return (
    <Grid container item justifyContent="center">
      <Grid container item xs="auto" border="1px dashed #D1D4D6" display="flex" justifyContent="center" alignItems="center">
        <Grid item container width={80} textAlign="center" justifyContent="space-evenly" flexDirection="column" height="128px" className="summary" >
          <Grid item className="bold">{translations["lbl.react.resumo.iatf"]}</Grid>
          <Grid item>{translations["lbl.react.resumo.1.iatf"]}</Grid>
          <Grid item>{translations["lbl.react.resumo.2.iatf"]}</Grid>
          <Grid item>{translations["lbl.react.resumo.3.iatf"]}</Grid>
        </Grid>
        <Grid item container width={98} textAlign="center" borderLeft="1px dashed #D1D4D6" justifyContent="space-evenly" flexDirection="column" height="128px" className="summary" >
          <Grid item className="bold">{translations["lbl.react.resumo.inseminacoes"]}</Grid>
          <Grid item>{ separateByIatf1(data).inseminationTotal }</Grid>
          <Grid item>{ separateByIatf2(data).inseminationTotal }</Grid>
          <Grid item>{ separateByIatf3(data).inseminationTotal }</Grid>
        </Grid>
        <Grid item container width={62} textAlign="center" borderLeft="1px dashed #D1D4D6" justifyContent="space-evenly" flexDirection="column" height="128px" className="summary" >
          <Grid item className="bold">{translations["lbl.react.resumo.prenhas"]}</Grid>
          <Grid item>{ separateByIatf1(data).conceptionTotal }</Grid>
          <Grid item>{ separateByIatf2(data).conceptionTotal }</Grid>
          <Grid item>{ separateByIatf3(data).conceptionTotal }</Grid>
        </Grid>
        <Grid item container width={124} textAlign="center" borderLeft="1px dashed #D1D4D6" justifyContent="space-evenly" flexDirection="column" height="128px" className="summary" >
          <Grid item className="bold">{translations["lbl.react.resumo.taxa.concepcao.summary"]}</Grid>
          <Tooltip title={translations["lbl.react.tooltip.numero.1.iatf"]}><Grid item>{ (separateByIatf1(data).percentage).toFixed(2).replace(".", ",") + "%" }</Grid></Tooltip>
          <Tooltip title={translations["lbl.react.tooltip.numero.2.iatf"]}><Grid item>{ (separateByIatf2(data).percentage).toFixed(2).replace(".", ",")+ "%" }</Grid></Tooltip>
          <Tooltip title={translations["lbl.react.tooltip.numero.3.iatf"]}><Grid item>{ (separateByIatf3(data).percentage).toFixed(2).replace(".", ",") + "%" }</Grid></Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}