import { Box, Button, Grid, SelectChangeEvent, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useContext } from 'react';
import SelectCustom from './SelectCustom';
import SwitchCustom from './SwitchCustom';
import DataPickerCustom from './RangeDataPicker';
import { getCombos } from '../apis/apiCombos';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext, SelectedFiltersContext } from '../App';
import { filterItems } from '../utils/configFilters';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import MultipleSelectCustom from './MultipleSelectCustom';

interface FiltersType {
  [key: string]: Date | null | string | number[],
  startDate: Date | null,
  endDate: Date | null,
  espCodigo: string,
  racCodigo: string,
  sexo: string,
  lotCodigo: string,
  catCodigo: string,
  statusCria: string,
  tipoBusca: string,
  cobTipo: string,
  ageInMonth: number[]
}

interface FiltersProps {
  handleSearch: any
  datePlaceholder: string
  selectedTab: string
  showFilters: any
  onlyMonths: boolean
  dateFormat: string
  dateTooltip?: string
  handleConsiderarDescartados?: any
  considerarDescartados?: boolean
}

export default function Filter(props: FiltersProps) {
  const { handleSearch, datePlaceholder, selectedTab, showFilters, onlyMonths, dateFormat, dateTooltip, considerarDescartados, handleConsiderarDescartados } = props;

  const [searchParams] = useSearchParams();
  const { translations } = useContext(LanguageContext);
  const { setSelectedFilters } = useContext(SelectedFiltersContext);
  const [filters, setFilters] = React.useState<FiltersType>({
    startDate: null,
    endDate: null,
    espCodigo: '',
    racCodigo: '',
    sexo: '',
    lotCodigo: '',
    catCodigo: '',
    statusCria: '',
    tipoBusca: '',
    cobTipo: '',
    ageInMonth: []
  });

  const [combos, setCombos] = React.useState<any>({
    camCombo: [],
    catCombo: [],
    espCombo: [],
    lotCombo: [],
    racCombo: [],
    sexCombo: [{sexName: translations["lbl.react.macho"], sexCodigo: "M"}, {sexName: translations["lbl.react.femea"], sexCodigo: "F"}],
    statusCombo: [{ statusCria: "L", statusName: translations["lbl.react.tabela.em.estoque"] }, { statusCria: "V", statusName: translations["lbl.react.tabela.vendidos"] }, { statusCria: "B", statusName: translations["lbl.react.tabela.baixas"] }, { statusCria: "T", statusName: translations["lbl.react.tabela.transferidos"] }]
  })

  const [fetchedCombos, setFetchedCombos] = React.useState<any>({
    camCombo: [],
    catCombo: [],
    espCombo: [],
    lotCombo: [],
    racCombo: []
  })

  const handleCombos = useCallback(async () => {
    const result = await getCombos(searchParams.get('pro_codigo'), searchParams.get('token'));
    if (result) {
      setFetchedCombos(result)
      setCombos((oldValue: any) => (
        { ...oldValue, ...result }
      ))
    }
  }, [searchParams]);

  const setCascadeCombos = (selectComboName: string, selectComboValue: string) => {
    if (selectComboName === 'espCodigo') {
      if (!selectComboValue) {
        setCombos({ ...combos, catCombo: fetchedCombos.catCombo, racCombo: fetchedCombos.racCombo })
      }
      else {
        let catComboItems = []
        if (selectedTab === "concepcao") {
          catComboItems = fetchedCombos.catCombo.filter((cat: { espCodigo: string, catSexo: string, catDesmamado: string }) => cat.espCodigo === selectComboValue && cat.catSexo === "F" && cat.catDesmamado === "S")
        } else if (selectedTab === "previsao") {
          catComboItems = fetchedCombos.catCombo.filter((cat: { espCodigo: string, catSexo: string }) => cat.espCodigo === selectComboValue && cat.catSexo === "F")
        } else {
          catComboItems = fetchedCombos.catCombo.filter((cat: { espCodigo: string }) => cat.espCodigo === selectComboValue)
        }
        const racComboItems = fetchedCombos.racCombo.filter((rac: { espCodigo: string; }) => rac.espCodigo === selectComboValue)
        setCombos({ ...combos, catCombo: catComboItems, racCombo: racComboItems })
        setFilters((prevState) => ({
          ...prevState,
          espCodigo: selectComboValue,
          racCodigo: '',
          sexo: '',
          lotCodigo: '',
          catCodigo: '',
          statusCria: '',
          ageInMonth: []
        }))
      }
    }
    if (selectComboName === 'catCodigo') {
      if (!selectComboValue) {
        setCombos({ ...combos, sexCombo: [{sexName: translations["lbl.react.macho"], sexCodigo: "M"}, {sexName: translations["lbl.react.femea"], sexCodigo: "F"}] })
      }
      else {
        const catComboSex = fetchedCombos.catCombo.find((cat: { catCodigo: string; }) => cat.catCodigo === selectComboValue).catSexo
        setCombos({ ...combos, sexCombo: [{sexName: catComboSex === "M" ? translations["lbl.react.macho"] : translations["lbl.react.femea"], sexCodigo: catComboSex}] })
      }
    }
    if (selectComboName === 'clear') {
      let catComboItems = []
      if (selectedTab === "previsao") {
        catComboItems = fetchedCombos.catCombo.filter((cat: { catSexo: string; }) => cat.catSexo === "F")
      } else {
        catComboItems = fetchedCombos.catCombo
      }
      setCombos({
        ...combos,
        sexCombo: [{sexName: translations["lbl.react.macho"], sexCodigo: "M"}, {sexName: translations["lbl.react.femea"], sexCodigo: "F"}],
        catCombo: catComboItems,
        racCombo: fetchedCombos.racCombo
      })
    }
  }

  useEffect(() => {
    handleCombos();
  }, [handleCombos]);

  useEffect(() => {
    let start = dayjs();
    let end = dayjs();

    // Alterar também na linha 296
    if (["nascimentos", "prenhez", "concepcao", "desmames_ocorridos"].includes(selectedTab)) {
      start = start.subtract(11, "months");
    } else {
      end = end.add(11, "months");
    }

    if (["previsao", "nascimentos", "concepcao", "previsao_desmama",  "desmames_ocorridos"].includes(selectedTab)) {
      start = start.startOf('month');
      end = end.endOf('month');
    }

    setFilters(_prevState => {
      return{
        statusCria: '',
        espCodigo: '',
        racCodigo: '',
        sexo: '',
        lotCodigo: '',
        catCodigo: '',
        cobTipo: '',
        startDate: start.toDate(),
        endDate: end.toDate(),
        ageInMonth: [],
        tipoBusca: selectedTab === "previsao_desmama" || selectedTab === "desmames_ocorridos" ? "1" : ""
      }
    })

  }, [selectedTab]);

  useEffect(() => {
    let catComboItems: [] = [];
    let sexComboItems: {}[] = [];
    let ageInMonthComboItems: number[] = [];
    if (selectedTab === "concepcao") {
      catComboItems = fetchedCombos.catCombo.filter((cat: { catSexo: string, catDesmamado: string }) => cat.catSexo === "F" && cat.catDesmamado === "S")
    } else if (selectedTab === "previsao") {
      catComboItems = fetchedCombos.catCombo.filter((cat: { catSexo: string; }) => cat.catSexo === "F")
      sexComboItems = [{sexName: translations["lbl.react.femea"], sexCodigo: "F"}]
    } else if (selectedTab === "desmames_ocorridos") {
      catComboItems = fetchedCombos.catCombo;
      sexComboItems = [{sexName: translations["lbl.react.macho"], sexCodigo: "M"}, {sexName: translations["lbl.react.femea"], sexCodigo: "F"}];
      ageInMonthComboItems = [4, 5, 6, 7, 8, 9, 10, 11, 12];
    } else {
      catComboItems = fetchedCombos.catCombo
      sexComboItems = [{sexName: translations["lbl.react.macho"], sexCodigo: "M"}, {sexName: translations["lbl.react.femea"], sexCodigo: "F"}]
    }

    setCombos((prevState: any) => (
      {
        ...prevState,
        catCombo: catComboItems,
        sexCombo: sexComboItems,
        lotCombo: fetchedCombos.lotCombo.sort((a: any, b: any) => a.lotNome.localeCompare(b.lotNome)),
        ageInMonthCombo: ageInMonthComboItems
      }
    ));
  }, [fetchedCombos.catCombo, fetchedCombos.lotCombo, selectedTab, translations])

  const mapFiltersToPrint = useCallback(() => {
    let mappedFiltersToPrint = Object.keys(filters).reduce((acc, currFilter) => {
      switch (currFilter) {
        case "statusCria":
          if (filters[currFilter] === "") {
            Object.defineProperty(acc, currFilter, {value: translations["lbl.react.todos"]});
          } else {
            Object.defineProperty(acc, currFilter, {value: combos.statusCombo.filter((currStatus: any) => currStatus.statusCria === filters[currFilter])[0].statusName});
          }
          break;
        case "espCodigo":
          if (filters[currFilter] === "") {
            Object.defineProperty(acc, currFilter, {value: translations["lbl.react.todas"]});
          } else {
            Object.defineProperty(acc, currFilter, {value: combos.espCombo.filter((currEsp: any) => currEsp.espCodigo === filters[currFilter])[0].espNome});
          }
          break;
        case "racCodigo":
          if (filters[currFilter] === "") {
            Object.defineProperty(acc, currFilter, {value: translations["lbl.react.todas"]});
          } else {
            Object.defineProperty(acc, currFilter, {value: combos.racCombo.filter((currRac: any) => currRac.racCodigo === filters[currFilter])[0].racNome});
          }
          break;
        case "sexo":
          if (filters[currFilter] === "") {
            Object.defineProperty(acc, currFilter, {value: translations["lbl.react.todos"]});
          } else {
            Object.defineProperty(acc, currFilter, {value: filters[currFilter] === "F" ? translations["lbl.react.femea"] : translations["lbl.react.macho"]});
          }
          break;
        case "lotCodigo":
          if (filters[currFilter] === "") {
            Object.defineProperty(acc, currFilter, {value: translations["lbl.react.todos"]});
          } else {
            Object.defineProperty(acc, currFilter, {value: combos.lotCombo.filter((currLot: any) => currLot.lotCodigo === filters[currFilter])[0].lotNome});
          }
          break;
        case "tipoBusca":
          if (filters[currFilter] === "") {
            Object.defineProperty(acc, currFilter, {value: translations["lbl.react.todos"]});
          } else {
            if (selectedTab === "previsao_desmama" || selectedTab === "desmames_ocorridos")
            Object.defineProperty(acc, currFilter, {value: filterItems(translations).tipoBusca.desmames_ocorridos.find((tipo: any) => tipo.valueKey === filters.tipoBusca)?.value });
          }
          break;
        case "catCodigo":
          if (filters[currFilter] === "") {
            Object.defineProperty(acc, currFilter, {value: translations["lbl.react.todas"]});
          } else {
            Object.defineProperty(acc, currFilter, {value: combos.catCombo.filter((currCat: any) => currCat.catCodigo === filters[currFilter])[0].catNome});
          }
          break;
        case "startDate":
          Object.defineProperty(acc, currFilter, {value: filters[currFilter]});
          break
        case "endDate":
          if (selectedTab === "previsao_desmama") {
            Object.defineProperty(acc, currFilter, {value: filters[currFilter]});
          } else {
            Object.defineProperty(acc, currFilter, {value: filters[currFilter]});
          }
          break;
        }
      return acc;
    }, {});

    return mappedFiltersToPrint;
  }, [filters, combos, translations, selectedTab]);

  useEffect(() => {
    setSelectedFilters(mapFiltersToPrint());
  }, [setSelectedFilters, mapFiltersToPrint])

  const selectHandleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value, name },
    } = event;
    setFilters({...filters, ...{[name]:value}})
    setCascadeCombos(name, value)
  };

  const clearFilters = () => {
    let start = dayjs();
    let end = dayjs();

    // Alterar também na linha 151
    if (["nascimentos", "prenhez", "concepcao", "desmames_ocorridos"].includes(selectedTab)) {
      start = start.subtract(11, "months");
    } else {
      end = end.add(11, "months");
    }

    if (["previsao", "nascimentos", "concepcao", "previsao_desmama",  "desmames_ocorridos"].includes(selectedTab)) {
      start = start.startOf('month');
      end = end.endOf('month');
    }

    setFilters({
      startDate: start.toDate(),
      endDate: end.toDate(),
      espCodigo: '',
      racCodigo: '',
      sexo: '',
      lotCodigo: '',
      catCodigo: '',
      statusCria: '',
      tipoBusca: '1',
      cobTipo: '',
      ageInMonth: []
    })
    setCascadeCombos("clear", "")
  }

  const datePickerHandleChange = ([newStartDate, newEndDate]: [newStartDate: Date, newEndDate: Date]) => {
    setFilters({...filters, ...{ startDate: newStartDate, endDate: newEndDate }})
  };

  const datePickerHandleClear = () => {
    setFilters({...filters, ...{ startDate: null, endDate: null }})
  };

  const datePickerHandleOutsideClick = () => {
    if (filters.endDate == null) setFilters({...filters, ...{ endDate: filters.startDate }})
  }

  const handleSearchButton = () => {
    setSelectedFilters(mapFiltersToPrint());
    handleSearch(filters);
  }

  return (
    <div>
      <Grid container >
        <Box sx={{ width: "100%", backgroundColor: "#efefef", border: "1px solid #e7ebee", borderRadius: "3px"} }>
          <Grid container height="180px" padding="22px" columnGap={2}>
            <Grid item xs={12}>
              <span className="filter-label">{ translations["lbl.react.filter.buscar.por"] }</span>
            </Grid>
            <Grid container item xs={12} justifyContent="space-between" wrap="wrap">
              { showFilters.tipoBusca && <Grid item xs pr={2}>
                <SelectCustom
                  placeholder=""
                  handleChange={selectHandleChange}
                  items={selectedTab === "previsao_desmama" ? filterItems(translations).tipoBusca.previsao_desmame : selectedTab === "desmames_ocorridos" ? filterItems(translations).tipoBusca.desmames_ocorridos : []}
                  selectedItem={filters.tipoBusca}
                  name="tipoBusca"
                />
              </Grid>}
              <Grid item container xs pr={2} wrap='nowrap'>
                <Grid xs item>
                  <DataPickerCustom
                    onlyMonths={onlyMonths}
                    dateFormat={dateFormat}
                    handleChange={datePickerHandleChange}
                    handleClear={datePickerHandleClear}
                    handleClickOutside={datePickerHandleOutsideClick}
                    startDate={filters.startDate}
                    endDate={filters.endDate}
                    placeholder={datePlaceholder}
                    limitMaxDate={["previsao", "previsao_desmama"].includes(selectedTab)  ? false : true}
                  />            
                </Grid>
                { dateTooltip &&
                  <Grid item ml={1} pt={1}>
                    <Tooltip title={dateTooltip}><InfoIcon className='info-icon' fontSize='small'/></Tooltip>
                  </Grid>
                }
              </Grid>
              { showFilters.tipoPrenhez && <Grid item xs pr={2}>
                <SelectCustom
                  placeholder={translations["lbl.react.tabela.tipo prenhez"]}
                  handleChange={selectHandleChange}
                  items={
                    [
                      {value: translations["lbl.react.filter.iatf"], valueKey: "0"},
                      {value: translations["lbl.react.filter.monta.natural"], valueKey: "1"},
                      {value: translations["lbl.react.ia.convencional"], valueKey: "2"}
                    ]
                  }
                  selectedItem={filters.cobTipo}
                  name="cobTipo"
                />
              </Grid>}
              { showFilters.espCodigo && <Grid item xs pr={2}>
                <SelectCustom
                  placeholder={translations["lbl.react.filter.especie"]}
                  handleChange={selectHandleChange}
                  items={combos.espCombo.map((especie: { espNome: any; espCodigo: any; }) => ({value: especie.espNome, valueKey: especie.espCodigo}))}
                  selectedItem={filters.espCodigo}
                  name="espCodigo"
                />
              </Grid>}
              { showFilters.racCodigo && <Grid item xs pr={2}>
                <SelectCustom
                  placeholder={translations["lbl.react.filter.raca"]}
                  handleChange={selectHandleChange}
                  items={combos.racCombo.map((raca: { racNome: any; racCodigo: any; }) => ({value: raca.racNome, valueKey: raca.racCodigo}))}
                  selectedItem={filters.racCodigo}
                  name="racCodigo"
                />
              </Grid>}
              { showFilters.catCodigo && <Grid item xs pr={2}>
                <SelectCustom
                  placeholder={translations["lbl.react.filter.categoria"]}
                  handleChange={selectHandleChange}
                  items={combos.catCombo.map((categoria: { catNome: any; catCodigo: any; }) => ({value: categoria.catNome, valueKey: categoria.catCodigo}))}
                  selectedItem={filters.catCodigo}
                  name="catCodigo"
                />
              </Grid>}
              { showFilters.sexo && <Grid item xs pr={2}>
                <SelectCustom
                  placeholder={translations["lbl.react.filter.sexo"]}
                  handleChange={selectHandleChange}
                  items={combos.sexCombo.map((sexo: { sexName: any; sexCodigo: any; }) => ({value: sexo.sexName, valueKey: sexo.sexCodigo}))}
                  selectedItem={filters.sexo}
                  name="sexo"
                />
              </Grid>}
              { showFilters.ageInMonthCombo && <Grid item xs pr={2}>
                <MultipleSelectCustom
                  placeholder={translations["lbl.react.filter.idade.em.meses"]}
                  handleChange={selectHandleChange}
                  items={combos.ageInMonthCombo}
                  selectedItem={filters.ageInMonth}
                  name="ageInMonth"
                />
              </Grid>}
              { showFilters.statusCria && <Grid item xs pr={2}>
                <SelectCustom
                  placeholder={translations["lbl.react.filter.status"]}
                  handleChange={selectHandleChange}
                  items={combos.statusCombo.map((status: { statusName: any; statusCria: any; }) => ({value: status.statusName, valueKey: status.statusCria}))}
                  selectedItem={filters.statusCria}
                  name="statusCria"
                />
              </Grid>}
              { showFilters.lotCodigo && <Grid item xs>
                <SelectCustom
                  placeholder={translations["lbl.react.filter.lote"]}
                  handleChange={selectHandleChange}
                  items={combos.lotCombo.map((lote: { lotNome: any; lotCodigo: any; }) => ({value: lote.lotNome, valueKey: lote.lotCodigo}))}
                  selectedItem={filters.lotCodigo}
                  name="lotCodigo"
                />
              </Grid>}
            </Grid>
            { showFilters.considerarDescartados && <Grid container item xs={12} justifyContent="space-between">
              <Grid item xs marginTop={1} marginLeft={1}>
                <SwitchCustom
                  considerarDescartados={considerarDescartados || false}
                  handleConsiderarDescartados={handleConsiderarDescartados}
                  label="Considerar fêmeas descarte como aptas"
                />
              </Grid>
            </Grid>}
            <Grid item container xs={12} justifyContent="end" display="flex" alignItems="center" columnSpacing={2}>
              <Grid item>
                <Button onClick={clearFilters} className="btn-secundario" variant="contained">{ translations["lbl.react.btn.limpar"] }</Button>
              </Grid>
              <Grid item>
                <Button onClick={() => handleSearchButton()} className="btn-primario" variant="contained">{ translations["lbl.react.btn.buscar"] }</Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
}