import axios from 'axios';

async function getCombos(proCodigo: string | null, token?: string | null) {
  try {
    const response = await axios({
      headers: {
        Authorization: token
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/generic/combos?proCodigo=${proCodigo}`,
    });
    return response.data
  } catch (error) {
    window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/login`
    console.error(error);
  }
}

export { getCombos };
