import * as React from 'react';
import Switch from '@mui/material/Switch';
import { FormControlLabel, Typography } from '@mui/material';

interface PropsType {
  handleConsiderarDescartados?: any;
  considerarDescartados: boolean;
  label: string;
}

export default function SwitchCustom(props: PropsType) {
  const { handleConsiderarDescartados, considerarDescartados, label } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    handleConsiderarDescartados(!considerarDescartados)
  };

  return (
    <FormControlLabel
      control={
        <Switch
        checked={considerarDescartados}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }} />
      }
      label={<Typography className="switch_control_label">{label}</Typography>}
    />
  );
}