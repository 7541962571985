import { Grid, Tooltip } from '@mui/material';
import '../../assets/css/genericSummaryTotalizer.scss';
import InfoIcon from '@mui/icons-material/Info';

type TooltipType = [number, string][];

interface HeaderData {
  tooltips: TooltipType[];
  data: Array<string | number>;
}

interface ChartData {
  headerData: HeaderData;
  contentData: Array<string | number | Array<string | number>>;
}

interface GenericSummaryTotalizerProps {
  chartData: ChartData;
  skipFirstHeader?: boolean;
  tooltipIcon?: boolean;
}

export default function GenericSummaryTotalizer(props: GenericSummaryTotalizerProps) {
  const keyForMap = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  const { chartData, tooltipIcon = true } = props;

  //  chartData deve ser um objeto no seguinte formato:
  //  {
  //    headerData: {
  //      tooltips: [ [index, textoDoTooltip], [index, textoDoTooltip] ],
  //      data: [ value, value, value, value, value ]
  //    } 
  //    contentData: [ [ nome, value, value, value, value... ], [ nome, value, value, value, value... ]... ]
  //  }
  //
  //  Os dados são um array com subarrays que correspondem a cada linha da tabela.
  //  Os tooltips são uma tupla no formato: [index, textoDoTooltip].

  const getTooltipIfExists =  (index: number) => {
    let result = chartData.headerData.tooltips.find((item: any) => item[0] === index);
    return result ? result[1] : false;
  }

  return (
    <Grid sx={{ maxWidth: '100%', minWidth: 500 }}>
      <table aria-label="custom table" className='generic-summary-totalizer'>
        <thead>
          <tr>
            { chartData && chartData.headerData && chartData.headerData.data.map((item: string | number, index: any) => {
              const key = item.toString() + index + keyForMap;
              const hasTooltip = getTooltipIfExists(index);
              
              if (hasTooltip) {
                return <th><Tooltip key={key} title={hasTooltip}><span>{item} {tooltipIcon && <InfoIcon fontSize="small" className="info-icon" />}</span></Tooltip></th>;
              } else {
                return <th key={key}>{item}</th>;
              }
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            { chartData && chartData.contentData && chartData.contentData.map((value: any, index: number) => {
              const key = index + String(value) + keyForMap;

              return (<td key={key}>{value}</td>);
              })}
          </tr>
        </tbody>
      </table>
    </Grid>
  );
}