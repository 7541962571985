import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabsProps {
  handleChange?: any;
  value: string;
  labels: {
    label: string,
    name: string
  }[];
}

export default function TabsCustom(props: TabsProps) {
  const { value, handleChange, labels } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: "#b2bf00"
          }
        }}
      >
        {
          labels.map(({ label, name }) => (<Tab label={label} key={name} value={name}/>))
        }
      </Tabs>
    </Box>
  );
}