import * as React from 'react';
import { Grid, Tooltip } from '@mui/material';
import { separateByIatfTotal, separateByIatfTotalRepasse, separateByRepasse, separateByPrenhez } from '../../utils/separateData';
import { LanguageContext } from '../../App';
import { useContext } from 'react';

interface ConceptionSummaryProps {
  data: Reproduction[];
  daysInterval: number;
  showPrenhez?: boolean;
  showIatf?: boolean;
  showIatfFinal?: boolean;
}

export default function ConceptionSummary(props: ConceptionSummaryProps) {
  const { data, daysInterval, showIatf, showIatfFinal, showPrenhez, } = props
  const { translations } = useContext(LanguageContext);

  const calculateTotalIatfs = (data: Reproduction[]) => {
    return data.reduce((acc: number, reproduction:Reproduction) => {
      if (reproduction.cobData && (reproduction.cobData.length > 0 || reproduction.cobIndN2 || reproduction.cobIndN3)) {
        const iatf1 = 1
        return acc = acc + (iatf1 + (reproduction.cobIndN2 ? reproduction.cobIndN2 : 0) + (reproduction.cobIndN3 ? reproduction.cobIndN3 : 0))
      } else {
        return acc;
      }
    }, 0)
  }

  return (
    <Grid container item pr={2} pl={5}>
      <Grid item xs={12} textAlign="center" fontSize="14" mb={2} color="#344767">
        <span>{translations["lbl.react.resumo"]}</span>
      </Grid>
      <Grid container item xs={12} border="1px dashed #D1D4D6" display="flex" justifyContent="center" alignItems="center">
        <Grid item container xs={8} justifyContent="space-evenly" flexDirection="column" height="220px" pl={2} className="summary" >
          <Tooltip title={translations["lbl.react.tooltip.dias.filtrados"]}><Grid item>{translations["lbl.react.resumo.dias"]}</Grid></Tooltip>
          <Tooltip title={translations["lbl.react.tooltip.femeas.aptas"]}><Grid item className="summary">{translations["lbl.react.resumo.femeas.aptas"]}</Grid></Tooltip>
          <Tooltip title={translations["lbl.react.tooltip.numero.inseminadas"]}><Grid item>{translations["lbl.react.resumo.femeas.inseminadas"]}</Grid></Tooltip>
          <Tooltip title={translations["lbl.react.tooltip.numero.inseminadas.percentual"]}><Grid item>{`${translations["lbl.react.resumo.femeas.inseminadas"]} (%)`}</Grid></Tooltip>
          <Tooltip title={translations["lbl.react.tooltip.numero.inseminacoes"]}><Grid item>{translations["lbl.react.resumo.total.inseminadas"]}</Grid></Tooltip>
          <Tooltip title={translations["lbl.react.tooltip.total.prenhas"]}><Grid item>{translations["lbl.react.resumo.total.prenhas"]}</Grid></Tooltip>
          <Tooltip title={translations["lbl.react.tooltip.iatfs.necessarias"]}><Grid item>{translations["lbl.react.resumo.relacao.prenhez"]}</Grid></Tooltip>
          {showIatf && <Tooltip title={translations["lbl.react.tooltip.total.prenhas.por.iatf"]}><Grid item className="summary-bold">{translations["lbl.react.resumo.taxa.concepcao"]}</Grid></Tooltip>}
          {showIatfFinal && <Tooltip title={translations["lbl.react.tooltip.numero.reapsse"]}><Grid item>{translations["lbl.react.resumo.prenhas.repasse"]}</Grid></Tooltip>}
          {showIatfFinal && <Tooltip title={translations["lbl.react.tooltip.numero.total"]}><Grid item className="summary-bold">{translations["lbl.react.resumo.taxa.concepcao.final"]}</Grid></Tooltip>}
          {showPrenhez && <Tooltip title={translations["lbl.react.tooltip.taxa.de.prenhez"]}><Grid item className="summary-bold">{translations["lbl.react.resumo.taxa.de.prenhez"]}</Grid></Tooltip>}
        </Grid>
        <Grid item container xs={4} borderLeft="1px dashed #D1D4D6" justifyContent="space-evenly" flexDirection="column" height="220px" pl={2} className="summary" >
          <Grid item>{ daysInterval }</Grid>
          <Grid item>{ data.length }</Grid>
          <Grid item>{ separateByIatfTotalRepasse(data).inseminationTotal }</Grid>
          <Grid item>{ separateByIatfTotalRepasse(data).conceptionTotal > 0 ? (separateByIatfTotalRepasse(data).inseminationTotal / data.length * 100).toFixed(2).replace(".", ",") + '%' : 0 }</Grid>
          <Grid item>{ calculateTotalIatfs(data) }</Grid>
          <Grid item>{ separateByIatfTotal(data).conceptionTotal }</Grid>
          <Grid item>{ separateByIatfTotal(data).conceptionTotal > 0 ? (calculateTotalIatfs(data) / separateByIatfTotal(data).conceptionTotal).toFixed(2).replace(".", ",") : 0 }</Grid>
          {showIatf && <Grid item className="summary-bold">{ (separateByIatfTotal(data).percentage).toFixed(2).replace(".", ",") + "%" }</Grid>}
          {showIatfFinal && <Grid item>{ separateByRepasse(data).conceptionTotal }</Grid>}
          {showIatfFinal && <Grid item className="summary-bold">{ (separateByIatfTotalRepasse(data).percentage).toFixed(2).replace(".", ",") + '%' }</Grid>}
          {showPrenhez && <Grid item className="summary-bold">{ (separateByPrenhez(data).percentage).toFixed(2).replace(".", ",") + '%' }</Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
}