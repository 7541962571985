import { useContext } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR, es } from 'date-fns/locale';
import { LanguageContext } from '../App';
registerLocale('pt', ptBR);
registerLocale('es', es)

interface DatePickerProps {
  handleChange?: any,
  handleClear?: any,
  handleClickOutside?: any,
  placeholder: string,
  currentValue?: Date,
  onlyMonths: boolean | undefined,
  dateFormat: string,
  minimumDate?: Date | null,
  maximumDate?: Date | null
}

export default function DataPickerCustom(props: DatePickerProps) {
  const { handleChange, handleClear, handleClickOutside, placeholder, onlyMonths, dateFormat, minimumDate, maximumDate, currentValue } = props;
  const { translations, language } = useContext(LanguageContext);

  const mesEnum = translations ? [
    translations["lbl.react.jan"],
    translations["lbl.react.fev"],
    translations["lbl.react.mar"],
    translations["lbl.react.abr"],
    translations["lbl.react.mai"],
    translations["lbl.react.jun"],
    translations["lbl.react.jul"],
    translations["lbl.react.ago"],
    translations["lbl.react.set"],
    translations["lbl.react.out"],
    translations["lbl.react.nov"],
    translations["lbl.react.dez"],
  ] : [];
  
  const handleDateFormat = (date: Date | undefined) => {
    const day = date ? date.getDate() : "";
    const month = date ? date.getMonth() : "";
    const year = date ? date.getFullYear() : "";
    
    if (date && month && onlyMonths) return `${ mesEnum[month]} ${year}`;
    else if (date) return `${String(day).padStart(2, '0')}/${String((typeof month === 'number' ? month + 1 : month)).padStart(2, '0')}/${year}`;
    else return "";
  }

  return (
    <DatePicker
      className="select-custom_date"
      placeholderText={placeholder}
      selected={currentValue}
      onChange={handleChange}
      onCalendarOpen={handleClear}
      onClickOutside={handleClickOutside}
      dateFormat={dateFormat}
      showMonthYearPicker={onlyMonths}
      locale={language}
      value={handleDateFormat(currentValue)}
      minDate={minimumDate ? minimumDate : null}
      maxDate={maximumDate ? maximumDate : null}
    />
  );
};