import { useContext } from 'react';
import { LanguageContext } from '../../App';
import { Grid, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface ChartData {
  "value": number,
  "name": String,
  "rawData": []
};

interface HardCodedSummaryProps {
  chartData: ChartData[];
}

export default function HardCodedSummary(props: HardCodedSummaryProps) {
  const { chartData } = props;
  const { translations } = useContext(LanguageContext);

  const calculateAverageWeight = ((animals: Animal[]): string => {
    const totalNotZeroAnimals = animals.filter(animal => animal.pesoNascimento !== 0).length || animals.length;
    return (animals.reduce((acc: number, curr: Animal) => acc = acc + curr.pesoNascimento, 0) / totalNotZeroAnimals).toFixed(0);
  })

  return (
    <div>
      <Grid item container width={450} textAlign="center">
        <Grid width={150}></Grid>
        <Grid width={150}><span>{translations["lbl.react.n.de.animais"]}</span></Grid>
        <Grid width={150}><Tooltip title={translations["lbl.react.calculo.peso.medio.animais.pesagem"]}><Grid container justifyContent="center" alignItems="center"><span>{translations["lbl.react.peso.medio.kg"]} </span><InfoIcon className='info-icon hide-to-print' fontSize='small' style={{marginLeft: '5px'}}/></Grid></Tooltip></Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="center" className="chart-table" height="100%" pb={6} wrap="nowrap" flexDirection="column" alignItems="center">
        {
          chartData.map((data: ChartData, index: number) => (
              <Grid item container width={450}>
                <Grid width={150} borderRight="1px dashed #D1D4D6" lineHeight="20px" height="57px" display="flex" justifyContent="center" alignItems="center"><span style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{data.name}</span></Grid>
                <Grid width={150} borderRight="1px dashed #D1D4D6" lineHeight="20px" height="57px" display="flex" justifyContent="center" alignItems="center"><span>{data.value}</span></Grid>
                <Grid width={150} borderRight="1px dashed #D1D4D6" lineHeight="20px" height="57px" display="flex" justifyContent="center" alignItems="center"><span>{calculateAverageWeight(data.rawData)}</span></Grid>
              </Grid>
          ))
        }
      </Grid>
    </div>
  );
}